import axios from "axios";
import http from "./http"
const api = process.env.VUE_APP_API
// 在售列表
export const $apiSellGoods = (data) => http({ method: "get", url: "/Goods/Web/market", params: data });
// 在售列表搜索
export const $apiSellGoodsSearch = (data) => http({ method: "post", url:"/goods/detail/search", params: data })
// 在售列表搜索id
export const $apiSellGoodsIdSearch = (data) => http({ method: "post", url:"/goods/id/search", params: data })
// 在售列表批量下架
export const $apiSellBatchOutGoods = (data) => axios.post(`${api}/bulk/off/shelves`,data) 
// 在售列表批量修改价格
export const $updateSellBatchPrice = (data) => axios.post(`${api}/batch/update/goods`,data)
// 在售列表暂停销售
export const $apiStopSellGoods = (data) => axios.post(`${api}/bulk/pause/shelves`,data)



// 库存列表
export const $apiStockGoods = (data) => http({ method: "get", url: "/Goods/Web/stock", params: data })
// 库存列表搜索
export const $apiInventGoodsSearch = (data) => http({ method: "post", url:"/inventory/id/search", params: data })
// 库存列表批量上架
export const $putSellBatchGoods = (data) => axios.post(`${api}/batch/shelves`,data)
// 库存列表批量修改价格
export const $updateInventBatchPrice = (data) =>axios.post(`${api}/batch/update/inventory`,data) 
// 订单列表
export const $apiOrderList = (data) => http({ method: "get", url: "/Goods/Web/order", params: data })
// 上传
export const $apiUploadFile = (data) => http({ method: "get", url: "/sell/goods/upload", params: data })
/** @namespace:{库存在售总和}   @param { companyId }  */
export const $apiGoodsTotal = (data) => http({ method: "post", url: "/inventory/goods/number", params: data }) 
/** @namespace:{平台优惠卷列表}   @param { token, couponType:{ 1为商家(默认), 2为平台} }  */
export const $apiCouponsList = (data) => http({ method: "post", url: "/company/coupons", params: data }) 





/** @namespace:{优惠卷分发}   @param { token, couponsId, userPhone, number }  */
export const $apiSentCoupons = (data) => http({ method: "post", url: "/coupons/bind/user", params: data }) 



/** @namespace:{钱包数量}   @param { token }  */
export const $apiBalanceInfo = (data) => http({ method: "post", url: "/get/user/balance", params: data} ) 
/** @namespace:{创建订单 -> 自主订单}   @param { token, goodsId, count, money, user_phone }  */
export const $apiCreateOrder = (data) => http({ method: "post", url: "/Goods/create/order", params: data }) 
/** @namespace:{应收款}   @param { token}  */
export const $apiCollectionList = (data) => http({ method: "get", url: "/Goods/Web/collection", params: data }) 


// 席位管理
export const $apiSeatList = (data) => http({ method: "get", url: "/Goods/Web/seatList", params: data }) 
// 席位删除
export const $apiDelSeats = (data) => http({ method: "post", url: "/seats/apply", params: data }) 
// 席位添加
// export const $apiAddSeats = (data) => http({ method: "get", url: "/Goods/Web/seatList", params: data }) 
// 席位申请列表
export const $apiApplySeats = (data) => http({ method: "post", url: "/seats/list", params: data }) 
// 席位同意 {token, phone,companyId}
export const $apiAgreeSeats = (data) => http({ method: "post", url: "/seats/binding", params: data }) 
// 席位拒绝 {token, phone}
export const $apiRefuseSeats = (data) => http({ method: "post", url: "/seats/reject", params: data }) 



/** @namespace:{合同筛选}   @param { token }  */
export const $apiSearchContract = (data) => http({ method: "post", url: "/Goods/Web/market", params: data });

/** @namespace:{消息群发}   @param { token }  */
export const $apiMessSend = (data) => http({ method: "get", url: "/Web/group/send", params: data });

/** @namespace:{主动订单提交}   @param { token,user_phone,list }  */
export const $submitCustomOrder = (data) => http({ method: "post", url: "/Create/order/driving", params: data });



/** @namespace:{消息提示，订单提示，合同提示}   @param { token }  */
export const $apiMsgPrompt = (data) => http({ method: "get", url: "/user/Message", params: data });
export const $apiOrderPrompt = (data) => http({ method: "get", url: "/user/order/remind", params: data });
export const $apiContractPrompt = (data) => http({ method: "get", url: "/Goods/Web/ContractTips", params: data });
export const $apiPromptInfo = (data) => http({ method: "get", url: "/user/prompt/news", params: data });


//@ 在线库存
export const $apiInventorySearch = (data) => http({ method: "post", url: "/goods/online/search", params: data }); /** @api 在线库存搜索 */
export const $apiInventoryBatchDelete = (data) => http({ method: "post", url: "/batch/delete/inventory", params: data }); /** @api 库存批量删除 */


// api:我的流水
export const $apiRecordList = (data) => axios({ method: 'post', url: '/user/capital/water', params: data })         /** @资金流水 */
export const $apiRecordType = (data) => axios({ method: 'get', url: '/pay/log/type', params: data })               /** @资金交易类型 */



// api: 我的订单

export const $apiTransactionRecord = (data) => http({ method: 'post', url: '/company/transaction/list', data: data })    